module services {
    export module applicationcore {
        export class addressService implements interfaces.applicationcore.IAddressService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getAddressDetails(consignmentId: number, type: number): ng.resource.IResourceClass<interfaces.applicationcore.IAddress>
            {
                return this.$resource<interfaces.applicationcore.IAddress>(this.ENV.DSP_URL + "Address/GetAddressDetails", {
                    consignmentId: consignmentId,
                    type: type
                });
            }

            setAddressDetails(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler>
            {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Address/Update", {
                });
            }
        }
    }
    angular.module("app").service("addressService", services.applicationcore.addressService);
}